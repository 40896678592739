<template>
  <div class="index">
    <div class="chat-list">
      <div class="nav-title">MM邮箱</div>
      <div class="chat-list-user">
        <div v-for="(item,index) in chatList"
             :key="index"
             @click="lookChatRecords(index,item.path)"
             :class="current == index?'active-background':'user-info'">
          <div class="expression-steps">
            <p>{{item.name}}</p>
            <p v-if="item.num">{{item.num}}</p>
          </div>
          <div v-if="item.value != 1 && item.value != 2"
               class="empty"
               @click.stop="handleEmpty(item,index)">[清空]</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // 邮箱
  name: 'mailbox',
  components: {

  },
  props:{
    currentNum:{ //父组件传来的值
      typeof:Number,
      default:0
    }
  },
  data () {
    return {
      current: 0,
      chatList: [
        {
          value: 1,
          name: '收件箱',
          path: 'InboxDetail',
          num: 0,
          id:1
        },
        {
          value: 2,
          name: '发邮件',
          path: 'sendEmails',
        },
        {
          value: 3,
          name: '已发邮件',
          path: 'sentItems',
          num: 0,
          id:0
        },
        {
          value: 4,
          name: '草稿箱',
          path: 'draftBox',
          num: 0,
          id:1
        },
        {
          value: 5,
          name: '垃圾箱',
          path: 'dustbinBox',
          num: 0,
          id:2
        },
      ],
    }
  },
  created () {
     this.getNum()
  },
  watch:{
    currentNum(newVal,oldVal){
      this.current = newVal
    }
  },
  methods: {
    // 查看对应用户聊天记录
    lookChatRecords (index, path) {
      this.current = index
      this.$router.push({ path: path })
    },
    // 清空
    handleEmpty (val,index) {
      this.$emit('clearData',val,index)
    },
    //初始化各个数量
    getNum(){
       this.$api.email.emailStaticsList().then(res=>{
           console.log("邮件数量",res.result)
           this.chatList[0].num = res.result.receive
           this.chatList[2].num = res.result.sent
           this.chatList[3].num = res.result.draft
           this.chatList[4].num = res.result.garbage
       })
    },
  }
};
</script>

<style scoped>
.index {
  display: flex;
}
.chat-list {
  width: 253px;
  user-select: none;
  cursor: pointer;
  height: 100%;
  background: #ffffff;
}
.chat-list .chat-list-user {
  height: 100%;
  /* height: 473px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.nav-title {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-list-user .user-info {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.empty {
  font-size: 14px;
  color: #ff6632;
}
.user-info:hover {
  background: #f5f7fa;
}
.expression-steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #313233;
}
.expression-steps p:nth-child(1) {
  margin-right: 8px;
}
.expression-steps p:nth-child(2) {
  font-size: 14px;
  color: #7b7d80;
}
.active-background {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f5f7fa;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.msgBox {
  width: 300px;
}
</style>