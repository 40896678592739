<template>
  <div class="index">
    <!-- 组件 -->
    <!-- 侧边栏组件 -->
    <mail-box-nav ref="mailBoxNav" @clearData="clearData" :currentNum="currentNum"/>
    <!-- 路由组件 -->
    <router-view @updateNum="updateNum" @sendOk="sendOk"></router-view>
  </div>
</template>

<script>
import mailBoxNav from "../../../components/mail-box-nav/index.vue";
export default {
  components: {
    mailBoxNav
  },
  data(){
    return {
      emailNum:0, //邮件数量
      alrMailNum:0,//已发邮件数量
      dusMailNum:0, //垃圾箱邮件数量
      draftNum:0, //草稿数量
      currentNum:0, //当前在哪个tab
    }
  },
  watch:{
	$route(to,from){
	  console.log(from.path) // 从哪来
	  console.log(to.path) // 到哪去
    if(to.path=='/index/mailbox/InboxDetail'){
      this.currentNum = 0
    }
    if(to.path=='/index/mailbox/sendEmails'){
      this.currentNum = 1
    }
    if(to.path=='/index/mailbox/sentItems'){
      this.currentNum = 2
    }
    if(to.path=='/index/mailbox/draftBox'){
      this.currentNum = 3
    }
    if(to.path=='/index/mailbox/dustbinBox'){
      this.currentNum = 4
    }
	}
},
  methods:{
    updateNum(){
      this.$nextTick(()=>{
        this.$refs.mailBoxNav.getNum()
      })
    },
    //发送成功
    sendOk(){
      this.updateNum()
    },
    //清空
    clearData(val,i){
      // console.log('==========',val.path)
      //  this.$router.push({path:val.path,query:{isClear:1,name:val.name}})
      this.$confirm(
          "您要清空" + val.name + "吗？",
          "清空" + val.name,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            customClass: "msgBox",
          }
        ).then(() => {
           this.clearDataApi(val.id,val.name)
        });
    },
    //清空数据
    clearDataApi(type,name){
      this.$api.email.clearEmail({
        type
      }).then(res=>{
        this.$message({
          message: '清空' + name + '成功',
          type: 'success'
        });
        this.updateNum()
      })
    }
  }
};
</script>

<style>
.index {
  background: rgb(255, 255, 255);
  display: flex;
  height: 600px;
  /* width: 1000px; */
}
</style>